<template>
  <!--运力待对账详情-->
  <div>
    <div>
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <div>
          <span style="font-size: 24px;font-weight: 500;color: #707070;">对账单 {{details.channel_name}}公司，{{details.period[0]}} {{details.period[1]}}，{{details.status_name}}</span>
        </div>
        <div>
          <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
        </div>
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
              <el-row :gutter="15">
                <!--<el-col :lg="6" :md="12">-->
                <!--  <el-form-item label="救援城市:">-->
                <!--    &lt;!&ndash;<el-cascader&ndash;&gt;-->
                <!--    &lt;!&ndash;  ref="refCascader"&ndash;&gt;-->
                <!--    &lt;!&ndash;  :options="city"&ndash;&gt;-->
                <!--    &lt;!&ndash;  v-model="where.period"&ndash;&gt;-->
                <!--    &lt;!&ndash;  @change="handleChange"></el-cascader>&ndash;&gt;-->
                <!--    <el-input-->
                <!--      clearable-->
                <!--      v-model="where.rescue_area"-->
                <!--      placeholder="请输入"/>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <el-col :lg="6" :md="12">
                  <el-form-item label="救援项目:">
                    <el-select clearable v-model="where.items" placeholder="请选择">
                      <el-option v-for="(item) in ServicesAvailable" :label="item.label" :value="item.serial_number"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!--<el-col :lg="6" :md="12">-->
                <!--  <el-form-item label="订单状态:">-->
                <!--    <el-select clearable v-model="where.original_state" placeholder="请选择">-->
                <!--      <el-option-->
                <!--        v-for="(item) in dict.type.order_status"-->
                <!--        :label="item.label"-->
                <!--        :value="item.value"-->
                <!--      />-->
                <!--    </el-select>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <!--<el-col :lg="6" :md="12">-->
                <!--  <el-form-item label="对账状态">-->
                <!--    <el-select clearable v-model="where.reconciliation_status" placeholder="请选择">-->
                <!--      <el-option-->
                <!--        v-for="(item) in dict.type.reconciliation_status"-->
                <!--        :label="item.label"-->
                <!--        :value="item.value"-->
                <!--      />-->
                <!--    </el-select>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
              </el-row>
              <el-row :gutter="15">
                <el-col :span="24" style="text-align: center;">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="Datalist-botton" style="margin-left: 20px">
                <el-button class="custom-button" style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;" @click="openEdit(null)" v-if="details.status == 0">
                  <!--el-icon-devops 是自己命名的-->
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">发起对账</span>
                </el-button>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 30px;">订单数量：</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;">{{statistics.order_count}}</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 20px;">合同价：</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;">{{statistics.contract_price}}</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 20px;">整体补扣款：</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;">{{statistics.repair_thread_price}}</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 20px;">应付款：</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;">{{statistics.receivable}}</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 20px;">已付款：</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;">{{statistics.already_receivable}}</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 20px;">对账资料：</span>
                <span style="font-size: 18px;font-weight: 700;color: #fea837;cursor: pointer;" @click="openZiliao(null)">点击查看</span>
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @selectAll="selectAll"
              @currentChange="currentChange"
              @doubleClick="editCell"
            >

              <template v-slot:photo_integrity= "{ scope }">
                <div>
                  <img src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;" v-if="scope.row.photo_integrity == true">
                  <img src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;" v-else>
                </div>
              </template>
              <template v-slot:work_info= "{ scope }">
                <div>
                  <img src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;" v-if="scope.row.work_info == true">
                  <img src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;" v-else>
                </div>
              </template>

              <template v-slot:practical_track= "{ scope }">
                <img src="@/assets/images/home/yunli/duihao.png" alt="" v-if="scope.row.practical_track === true" style="width: 24px;height: 24px;">
                <img src="@/assets/images/home/yunli/cuohao.png" alt="" v-else style="width: 24px;height: 24px;">
              </template>

              <!--合同价-->
              <template v-slot:contract_price= "{ scope }">
                <div v-if="row===scope.row.id && col===scope.column.id">
                  <el-input placeholder="placeholder" v-model="scope.row.contract_price" oninput="value=value.replace(/[^\d.]/g, '').replace(/^0+(\d)/, '$1').replace(/^\./, '0.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^\./g, '')" @blur="updateData(scope.row)" clearable></el-input>
                </div>
                <div v-else>
                  {{ scope.row.contract_price }}
                </div>
              </template>

              <!--增值服务项-->
              <template v-slot:zengzhi= "{ scope }">
                <div v-if="scope.row.is_pulley == 1">
                  辅轮
                </div>
                <div v-else>

                </div>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="success" v-if="scope.row.facilitator_reconciliation_status == 0" @click="affirm(scope.row)">确认</el-link>
                <el-link :underline="false" type="danger"  v-if="scope.row.facilitator_reconciliation_status == 1" @click="revocation(scope.row)">撤销</el-link>
                <el-link :underline="false" type="danger" v-if="scope.row.facilitator_reconciliation_status == 0" @click="openBufu(scope.row)">不符</el-link>
                <el-link :underline="false" type="warning" @click="openJilu(scope.row)">对账记录</el-link>
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>

      <!--发起对账弹窗组件-->
      <initiateReconciliation-edit v-if="showEdit === true" :data="current" :visible.sync="showEdit" @done="queryDone"/>

      <!--不符原因弹窗-->
      <reasonForNonCompliance-edit v-if="showbufu === true" :data="bufucurrent" :visible.sync="showbufu" @done="getDetails"/>

      <!--对账资料弹窗-->
      <reconciliationInformation-edit v-if="showziliao === true" :id="id" :data="ziliaocurrent" :visible.sync="showziliao"/>

      <!--对账记录弹窗-->
      <reconciliationRecords-edit v-if="showjilu == true" :data="jilucurrent" :visible.sync="showjilu"/>

    </div>

  </div>
</template>

<script>
//引入发起对账弹窗组件
import InitiateReconciliationEdit from './components/initiateReconciliation-edit.vue'
//引入不符原因弹窗组件
import ReasonForNonComplianceEdit from './components/reasonForNonCompliance-edit.vue'
//引入对账资料弹窗组件
import ReconciliationInformationEdit from './components/reconciliationInformation-edit.vue'
//引入对账记录弹窗组件
import ReconciliationRecordsEdit from './components/reconciliationRecords-edit.vue'

// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  capacity_reconciliation,
  capacity_reconciliationaffirm,
  capacity_reconciliationrevocation, capacityedit_contract_price, edit_contract_price,
  getorder_list,
  statistics
} from "@/api/finance";
//服务项目接口
import {getget_services_available} from '@/api/yunli'
// 省市区接口
import {
  getcityList,
} from '@/api/yunli'

export default {
  props: {
    // 字典数据
    dict: Object,
    // 数据
    data: Object
  },
  // 组件生效
  components: {
    PackageSortElement,
    InitiateReconciliationEdit,
    ReasonForNonComplianceEdit,
    ReconciliationInformationEdit,
    ReconciliationRecordsEdit
  },
  data(){
    return{
      //详情数据
      details:{},
      //统计数据
      statistics:{},

      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "订单号",
          // type: "index",
          // width: "70"
          prop: "order_id",
          fixed:'left',
          isShow: true
        },
        {
          label: "报案号",
          prop: "case_number",
          isShow: true
        },
        {
          label: "救援城市",
          prop: "rescue_area",
          isShow: true
        },
        {
          label: "故障车牌",
          prop: "license_plate",
          isShow: true
        },
        {
          label: "救援类型",
          prop: "items_name",
          isShow: true
        },
        {
          label: "客户",
          prop: "owners",
          isShow: true
        },
        {
          label: "订单状态",
          prop: "status_label",
          isShow: true
        },
        {
          label: "去程",
          prop: "practical_arrival_mileage",
          isShow: true
        },
        {
          label: "背程",
          prop: "practical_return_mileage",
          isShow: true
        },
        // {
        //   label: "增值服务项",
        //   prop: "zengzhi",
        //   slost:'zengzhi',
        //   isShow: true
        // },
        {
          label: "照片完整",
          prop: "photo_integrity",
          slot: 'photo_integrity',
          isShow: true
        },
        {
          label: "工单完整",
          prop: "work_info",
          slot: 'work_info',
          isShow: true
        },
        {
          label: "轨迹完整",
          prop: "practical_track",
          slot: 'practical_track',
          isShow: true
        },
        {
          label: "计价公式",
          prop: "taximeter_name",
          isShow: true
        },
        {
          label: "合同价",
          prop: "contract_price",
          slot: 'contract_price',
          width: '150',
          isShow: true
        },
        {
          label: "对账状态",
          prop: "reconciliation_status-name",
          isShow: true
        },
        {
          label: "下单时间",
          prop: "order_time",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          fixed:'right',
          isShow: true
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      //发起对账弹窗
      current:null,
      showEdit:false,

      //不符原因
      bufucurrent:null,
      showbufu:false,

      //对账资料
      ziliaocurrent:null,
      showziliao:false,

      //对账记录
      jilucurrent:null,
      showjilu:false,

      //服务项目
      ServicesAvailable:[],
      //城市列表
      city:[],

      selection:[],

      id:0,

      // 修改合同价
      row: "",
      col: "",
      tmpRow: "",

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //调用获取详情数据的方法
    this.getDetails();
    //获取统计接口
    this.getstatistics();

    //获取服务项目
    this.getFuwuxiangmu();
    //获取城市列表
    this.getCity();
  },

  methods:{
    //获取详情
    getDetails(){
      capacity_reconciliation(this.data.id).then(res => {
        this.details = res.data;
        getorder_list(this.data.id,this.where).then(async (res) => {
          this.total = res.data.count;
          this.List = res.data.list;
          this.loading = false;
        }).catch((res) => {
          this.$Message.error(res.msg);
          this.loading = false;
        })
      })
    },

    //获取统计接口
    getstatistics(){
      statistics(this.data.id).then(res => {
        console.log(res)
        this.statistics = res.data;
      })
    },

    //获取服务项目
    getFuwuxiangmu(){
      getget_services_available().then(res => {
        this.ServicesAvailable = res.data;
      })
    },
    // 获取城市列表
    getCity(){
      getcityList().then(res => {
        // console.log(res);
        this.city = res.data;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      }
      this.getDetails();
    },

    // 修改合同价
    editCell: function (row, column) {
      this.row = row.id;
      this.col = column.id;
      this.tmpRow = row;
      this.tmpRow.id = row.id;
    },
    updateData(row) {
      let data = {
        contract_price: row.contract_price
      }
      // 修改合同价接口
      capacityedit_contract_price(row.id,data).then(res => {
        // console.log(res)
        if(res.code === 200){
          this.isUpdate = false;
          this.row = '';
          this.col = '';
          this.tmpRow = '';
          this.$message.success(res.msg);
          this.getDetails();
        }else {
          this.isUpdate = false;
          this.$message.error(res.msg);
        }
      })
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getDetails();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      this.selection = selection;
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getDetails()
    },
    // 表格全选事件
    selectAll(list){
      console.log(list)
      this.selection = list;
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 确认
    affirm(row){
      console.log(row);
      let that = this;
      this.$utils.confirm("确定要执行此操作吗？", function () {
        // 执行一些需要的逻辑
        capacity_reconciliationaffirm(row.id).then(res => {
          console.log(res)
          if(res.code == 200){
            that.$message.success(res.msg);
            that.getDetails()
          }else {
            that.$message.error(res.msg)
          }
        }).catch(err => {
          that.$message.error(err.msg)
        })
      }, "确定", 'warning');
    },

    // 撤销
    revocation(row){
      console.log(row);
      let that = this;
      this.$utils.confirm("确定要执行此操作吗？", function () {
        // 执行一些需要的逻辑
        capacity_reconciliationrevocation(row.id).then(res => {
          console.log(res)
          if(res.code == 200){
            that.$message.success(res.msg);
            that.getDetails()
          }else {
            that.$message.error(res.msg)
          }
        }).catch(err => {
          that.$message.error(err.msg)
        })
      }, "确定", 'warning');
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    //点击返回按钮
    returnPage(){
      this.$emit('showDetails',false);
    },

    //点击发起对账
    openEdit(row){
      if(this.selection.length == 0){
        this.$message.error("请选择要对账的订单");
      }else {
        let Repairthread = 0;
        let data = []
        this.selection.forEach(item => {
          data.push(parseFloat(item.contract_price))
        })
        console.log(data,'data')
        Repairthread = data.reduce(function(acc, val) {
          return acc + val;
        }, 0);
        console.log(Repairthread,'Repairthread')

        // 要传递的数据
        this.current = {
          object_name: this.data.object_name,
          period: this.data.period,
          id: this.data.id,
          order_count: this.statistics.order_count,
          contract_price: Repairthread
        }
        console.log(this.current,'current')
        this.showEdit = true;
      }
    },

    //点击不符
    openBufu(row){
      this.bufucurrent = row;
      this.showbufu = true;
    },

    //对账资料
    openZiliao(row){
      this.id = this.data.id;
      this.ziliaocurrent = row;
      this.showziliao = true;
    },

    //对账记录
    openJilu(row){
      this.jilucurrent = row;
      this.showjilu = true;
    },

    //选择区域
    //级联选择器
    handleChange(value) {
      const areaTextArr = [];
      const arr = this.$refs["refCascader"].getCheckedNodes()[0].pathNodes;
      arr.forEach((i) => {
        areaTextArr.push(i.label);
      });
      // console.log(areaTextArr);
      // console.log(value);
      let area = [];
      let province = value[0] + '|' + areaTextArr[0];
      let city = value[1] + '|' + areaTextArr[1];
      let district = value[2] + '|' + areaTextArr[2];
      area.push(province,city,district);
      console.log(area)
    },

    queryDone(){
      this.$emit('showDetails',false);
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
